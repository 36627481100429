import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from '../../../contexts/AuthContext'
import styled from 'styled-components'
import { borderRadius, colors, spacing } from '../../../styles/styles'
import { authenticatedFetch } from '../../../lib/authenticatedFetch'
import { environment } from '../../../environments'
import { useVideoCalls } from '../../../contexts/VideoCallsProvider'
import { ListItemLoader } from '../../shared/ListItemLoader'

const VideoCallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const CallHeader = styled.div`
  display: flex;
  padding: ${spacing.lg};
  align-items: center;
  justify-content: center;

  h1 {
    margin: 0;
  }
`

const IFrame = styled.iframe`
  flex: 3;
  border: none;
`

const ButtonGroup = styled.div`
  display: flex;
  padding: ${spacing.lg};
  align-items: center;
  justify-content: center;
`

const Button = styled.button`
  width: 300px;
  height: 48px;
  border: none;
  font-size: inherit;
  border-radius: ${borderRadius};
`

const AcceptButton = styled(Button)`
  margin-left: ${spacing.xl};
  color: white;
  background-color: ${colors.jodaDark};
`

const DeclineButton = styled(Button)`
  color: white;
  background-color: ${colors.error};
`

export const VideoCallPage = () => {
  const { authUser } = useContext(AuthContext)
  const {
    videoCall,
    onVideoCallDeclined,
    onVideoCallAccepted,
  } = useVideoCalls()
  const [status, setStatus] = useState<'idle' | 'pending' | 'failure'>('idle')
  const [audio] = useState(
    new Audio('https://www.soundjay.com/phone/sounds/telephone-ring-04.mp3')
  )
  const isVideoCallRecipient = videoCall.recipient_id === authUser?.id
  const displayName = isVideoCallRecipient
    ? videoCall.recipient_name
    : videoCall.sender_name

  const roomUrl = `${videoCall.room_url}?embed&iframeSource=jodacare&screenshare=off&chat=off&leaveButton=off&displayName=${displayName}&floatSelf`

  const caller = isVideoCallRecipient
    ? videoCall.sender_name
    : videoCall.recipient_name

  const acceptCall = () => {
    setStatus('pending')

    authenticatedFetch(
      `${environment.API_URL}/video-calls/${videoCall.id}/accept`,
      {
        method: 'PATCH',
      }
    )
      .then((call) => {
        setStatus('idle')
        onVideoCallAccepted(call)
      })
      .catch(() => setStatus('failure'))
    audio.pause()
  }

  const declineCall = () => {
    authenticatedFetch(`${environment.API_URL}/video-calls/${videoCall.id}`, {
      method: 'DELETE',
    })
      .then((response) => {
        setStatus('idle')
        onVideoCallDeclined()
      })
      .catch(() => {
        onVideoCallDeclined()
      })
    audio.pause()
  }
  useEffect(() => {
    if (videoCall && isVideoCallRecipient && !videoCall.accepted) {
      audio.play()
    }
  }, [videoCall, audio, isVideoCallRecipient])

  const callHasStarted = videoCall.accepted || !isVideoCallRecipient
  // @ts-ignore
  const isNative = window?.ReactNativeWebView
  return (
    <VideoCallWrapper>
      <CallHeader>
        <h1>
          {videoCall.accepted
            ? `Videosamtale med ${caller}`
            : isVideoCallRecipient
            ? `${caller} ringer!`
            : `Ringer ${caller}`}
        </h1>
      </CallHeader>

      {callHasStarted ? (
        <IFrame
          src={roomUrl}
          allow="camera; microphone; fullscreen; speaker; display-capture"
        />
      ) : (
        <ListItemLoader />
      )}

      {!videoCall.accepted && (
        <ButtonGroup>
          <DeclineButton disabled={status === 'pending'} onClick={declineCall}>
            {isVideoCallRecipient ? 'Avslå' : 'Legg på'}
          </DeclineButton>
          {isVideoCallRecipient && (
            <AcceptButton disabled={status === 'pending'} onClick={acceptCall}>
              Svar
            </AcceptButton>
          )}
        </ButtonGroup>
      )}

      {videoCall.accepted && !isNative && (
        <ButtonGroup>
          <DeclineButton disabled={status === 'pending'} onClick={declineCall}>
            Legg på
          </DeclineButton>
        </ButtonGroup>
      )}
    </VideoCallWrapper>
  )
}
