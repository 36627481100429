import React, { FunctionComponent, useContext } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { borderRadius, breakpoints, colors, spacing } from '../styles/styles'
import { AuthContext } from '../contexts/AuthContext'
import { Nav, NavBrand, NavContainer, NavItem, NavMenu } from './NavStyles'

const LargeScreenNavItem = styled(NavItem)`
  display: none;

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    display: block;
  }
`

const NavLinkStyled = styled(NavLink)`
  border-radius: ${borderRadius};
  padding: ${spacing.md};

  @media only screen and (min-width: ${breakpoints.MEDIUM}) {
    padding: ${spacing.md} ${spacing.lg};
  }

  &:link,
  &:visited {
    color: inherit;
    text-decoration: none;
  }

  &.active {
    color: white;
    background: ${colors.joda};
  }
`

export const NavBar: FunctionComponent = () => {
  const { settings } = useContext(AuthContext)

  return (
    <Nav>
      <NavContainer>
        <NavBrand>Jodabook</NavBrand>

        {settings && (
          <NavMenu>
            <LargeScreenNavItem>
              <NavLinkStyled to="/book" activeClassName="active">
                Boka
              </NavLinkStyled>
            </LargeScreenNavItem>

            <LargeScreenNavItem>
              <NavLinkStyled to="/album" activeClassName="active">
                Album
              </NavLinkStyled>
            </LargeScreenNavItem>

            <LargeScreenNavItem>
              <NavLinkStyled to="/profile" activeClassName="active">
                Meg
              </NavLinkStyled>
            </LargeScreenNavItem>

            {/* {settings.jodabook_contacts && (
              <LargeScreenNavItem>
                <NavLinkStyled to="/contacts" activeClassName="active">
                  Kontakter
                </NavLinkStyled>
              </LargeScreenNavItem>
            )} */}
          </NavMenu>
        )}
      </NavContainer>
    </Nav>
  )
}
