import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { authenticatedFetch } from '../lib/authenticatedFetch'
import { environment } from '../environments'

type VideoCall = {
  hasProvider: boolean
  videoCall: any
  setVideoCall: Dispatch<any>
}

const VideoCallContext = createContext<VideoCall>({
  hasProvider: false,
  videoCall: undefined,
  setVideoCall: () => {},
})

export const VideoCallProvider: FunctionComponent = ({ children }) => {
  const [hasProvider] = useState<boolean>(true)
  const [videoCall, setVideoCall] = useState<any>(undefined)

  const fetchVideoCalls = useCallback(async () => {
    // We don't want to fetch new video calls if one is already in progress
    if (!videoCall) {
      authenticatedFetch(`${environment.API_URL}/video-calls`)
        .then((response) => {
          if (response.results.length > 0) {
            setVideoCall(response.results[0])
          }
        })
        .catch((error) => {})
    } else {
      authenticatedFetch(`${environment.API_URL}/video-calls/${videoCall.id}`)
        .then((response) => {
          if (!response) {
            setVideoCall(undefined)
          }
        })
        .catch((error) => {
          setVideoCall(undefined)
        })
    }
  }, [videoCall])

  useEffect(() => {
    const id = setInterval(fetchVideoCalls, 5000)

    return () => clearInterval(id)
  }, [fetchVideoCalls])

  return (
    <VideoCallContext.Provider
      value={{
        hasProvider,
        videoCall,
        setVideoCall,
      }}
    >
      {children}
    </VideoCallContext.Provider>
  )
}

export const useVideoCalls = () => {
  const context = useContext(VideoCallContext)

  if (!context.hasProvider) {
    throw new Error('useVideoCalls must be used within a VideoCallProvider')
  }

  const { videoCall, setVideoCall } = context

  const onVideoCallDeclined = () => {
    setVideoCall(undefined)
  }

  const onVideoCallAccepted = (call: any) => {
    setVideoCall(call)

    // @ts-ignore
    const isNative = window?.ReactNativeWebView

    if (isNative) {
      // @ts-ignore
      window?.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'INCOMING_VIDEO_CALL',
          payload: call,
        })
      )
    }
  }

  const onVideoCallInitiated = (call: any) => setVideoCall(call)

  return {
    videoCall,
    onVideoCallDeclined,
    onVideoCallAccepted,
    onVideoCallInitiated,
  }
}
